<template>
    <div class="member-pay">
        <div class="container">
            <!-- 标题 -->
            <div class="member-pay-title">确认订单</div>
            <!-- 订单内容模块 -->
            <metope-order-info
                :metope-order="orderDetail">
                <template #name="{data}">
                    <div>
                        <span>名称：</span>
                        <span>{{data.name}}</span>
                    </div>
                </template>
                <template #type="{data}">
                    <div>
                        <span>预约时间：</span>
                        <span>{{data.bookTime}}</span>
                    </div>
                </template>
            </metope-order-info>
            <!-- 订单表单模块 -->
            <metope-order-form
                ref="payForm"
				:user-phone="orderDetail.userPhone"
				:user-name="orderDetail.userName"
				:user-area="orderDetail.userArea"
				:user-address="orderDetail.userAddress">>
            </metope-order-form>
            <!-- 结尾 -->
            <div class="member-pay-end">
                <div class="member-pay-end-price">
                    <span>合计价格：</span>
                    <span class="member-pay-end-price-num">{{order_detail.total}}</span>
                    <span>元</span>
                </div>
                <el-button
                    type="primary"
                    size="medium"
                    @click="handleSubmit"
                    class="member-pay-end-submit">
                    提交
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        computed: mapState({
            order_detail: state => state.order.order_detail,
        }),
		data() {
			return {
				orderDetail:{}
			}
		},
		watch:{
			order_detail(){
				this.orderDetail = this.order_detail
			}
		},
		mounted() {
			this.orderDetail = this.order_detail
		},
        components: {
            metopeOrderInfo: () => import("@/components/metope/metopeOrderInfo"),
            metopeOrderForm: () => import("@/components/metope/metopeOrderForm")
        },
        methods: {
            ...mapMutations([
                'setMemberOrder',
                'handleLoading'
            ]),
            ...mapActions([
                'getMemberOrderPay',
                'handleAddOrder'
            ]),
            getParams(orderID) {
                let url = `ushop/OrderInformation?orderID=${orderID}`;
                this.getMemberOrderPay(url)
            },
            getSubmitParams() {
                let url = 'ushop/OrderInformation',
                    form = this.$refs.payForm.form,
                    params = this.$qs.stringify({
                        orderID: this.order_detail.orderID,
                        name: form.name,
                        phone: form.phone,
                        city: form.city,
                        address: form.address,
                        remark: form.remark
                    },
                    {
                        arrayFormat: 'brackets'
                    });
                return {
                    url,
                    params
                }
            },
            handleSubmit(){
                let { url, params } = this.getSubmitParams();
                this.handleLoading(true);
                this.handleAddOrder({ url, params })
                    .then(res => {
                        let data = res.data;
                        this.$message({
                            showClose: true,
                            message: data.message,
                            type: data.code == 200? 'success': 'error'
                        })
                        if(data.code == 200){
                            this.$router.push({
                                name: 'memberOrder',
                                query: {
                                    id: this.order_detail.orderID
                                }
                            })
                        }
                    })
                    .catch(err => err)
                    .finally(() => {
                        this.handleLoading(false);
                    })
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                let orderID = to.query.id;

                vm.getParams(orderID)
            });
        },
        beforeRouteLeave (to, from, next) {
            this.setMemberOrder({})
            next();
        }
    }
</script>

<style scoped>
.member-pay{
    --color: #8d9afc;
    padding: 50px 0;
    background-color: #fafafa;
}
.member-pay-title{
    margin-bottom: 20px;
    letter-spacing: 4px;
    font-size: 20px;
}
.member-pay-end{
    margin-top: 20px;
    text-align: right;
}
.member-pay-end-price > span{
    letter-spacing: 2px;
    font-size: 16px;
}
.member-pay-end-price > .member-pay-end-price-num{
    margin-right: 6px;
    font-size: 22px;
    color: var(--color);
}
.member-pay-end-submit{
    width: 200px;
    margin-top: 20px;
    background-color: var(--color);
    border-color: var(--color);
    letter-spacing: 4px;
}
.member-pay-end-submit >>> span{
    font-size: 16px;
}
</style>